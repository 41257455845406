//Embedded Level 4 from road-map2 Component
.featured-phase {
  overflow: hidden;
    height: 55vh;
    vertical-align: middle;
    border-radius: 1rem;
    width: 25%;
    margin: 10px;
    padding: 0;
    background: rgba(48, 48, 47, 0.788);
    border: 5px solid rgba(78, 73, 2, 0.808);
    box-shadow: 0px 2px 4px rgba(0,0,0,0.2);
    margin-left: 1rem 0;
}
.retain-scale {
  text-align: left;
  display: flex;
  flex-direction: column;
  //Embedded Phase Level 1
  .phase-image img{
    width: 100%;
    height: 25vh;
  }
  .phase-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  h3 {
    height: 3vh
  }
  h5{
    height: 6vh;
  }
  
}

@media (min-width: 300px) and (max-width: 900px){
  .featured-phase{
    width:100% !important;
    margin: 1% !important
  }

}