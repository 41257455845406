@charset "utf-8";
.app {
  position: relative;
  min-height: 100vh;
}
img {
  width: 100%;
}
.nomad-container {
  width: 60%;
  margin: 0 auto;
}

//Universal Lomb Co Form CSS
//form-ontainer currently in each component
.form-container {
  display: block;
}
form {
  padding: 2rem; 
  text-align: center; 
  input[type="text"],
  input[type="date"],
  input[type="email"],
  input[type="password"] {
    width: 65%;
    padding: 10px !important;
    border: 2px solid goldenrod !important;
    border-radius: 5px;
    margin-bottom: 1.5rem !important;
  }
  .submit-btn {
    display: flex;
    justify-content: center;
  }
  .submit {
    width: 200px;
  }
}
.lomb-form-input {
  //width: 100%;  Can probably be deleted
  //padding: 10px;
  //margin-bottom: 1rem;
  background-color: rgba(124, 124, 124, 0.116);
}
.lomb-form-btn {
  display: block;
  width: 100%;
  line-height: 1.4;
  padding-left: 5px;
  padding-right: 5px;
  white-space: normal;
  margin-top: 1.5rem;
  margin-bottom: 10px;
  min-height: 44px;
  border: 2px solid goldenrod !important;
  border-radius: 5px;
  background-color: black;
  color: goldenrod;
  font-size: 1.5rem;
}



//Custom Homepage Buttons
.join-now {
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: #606060;
  }
  border-radius: 40%;
  border: 5px solid rgba(219, 209, 72, 0.75);
  background-color: rgba(124, 124, 124, 0.753);
  font-size: 30px;
  color: white;
  
}

.featured-collection {
  margin-top: 6rem;

  .featured-section-title {
    font-size: 2rem;
  }

  .featured-image {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    .products {
      display: flex;
      justify-content: space-between;
      padding: 5px;

      .featured-product {
        margin: 10px;
      }
    }
  }
}

//single product page
.name-price {
  p {
    font-size: 1.2rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1rem;
  }
}






.form-error {
  border: 1px solid red;
}

::placeholder {
  color: #606060;
  font-size: 16px;
}

#btn-white-outline {
  border: 1px solid grey;
  margin-bottom: 1rem;
}

#buy-it-now-btn {
  &:hover {
    background-color: #606060;
  }
}

/*@media (min-width: 1024px) {
  form {
    width: 65%;
    margin: 0 auto;

    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="password"] {
      width: 65%;
    }
  }
}*/

.error {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 1.5rem;
}

@media (min-width: 300px) and (max-width: 900px){
  .App {
    text-align: center;
    width: 100% !important;
    position: relative;
  }
}