//Component Entry Tag
.road-image {
    height: 90vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
      url("../../assets/landscape-background1.jpg") center/100% 100% no-repeat;
    background-color: rgba(163, 147, 75, 0.664);
  //Embedded Level 1
  .phase-containers {
    color: white;
    width: 100%;
    //Embedded Level 2
    .phase-title {
      font-size: 4rem;
      text-align: center;
      display:flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      height: 15vh;
    }
    //Embedded Level 2
    .wrapper {
      height: 70vh;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      //Embedded Level 3
      .phases {
          height: 65vh;
          display: flex;
          justify-content: space-between;
          //padding: 5px;
          //Embedded Level 4 sits within new featured phase component         
      }
    }
  }
}


@media (min-width: 300px) and (max-width: 900px){
  .App {
    text-align: center;
    width: 100% !important;
    position: relative;
  }
  //Component Entry Tag
  .road-image {
    height: 90vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../../assets/landscape-background1.jpg") center/100% 100% no-repeat;
    background-color: rgba(163, 147, 75, 0.664);
  }
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    overflow: scroll;
    overflow-x: hidden;
  }
  .phases {
    //height: 20rem !important;
    position: relative;
    min-width: 90%;
    width: 90%;
    display: block !important;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .phase-title {
    font-size: 2rem !important;
    text-align: center;
    display:block !important;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding-top: 10px;
    height: 7vh !important;
  }
}







  
    
  
    

  
  
    
    



/*@media (max-width: 650px) {
    .road-image {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
        url("../../assets/LombCoInitialRoadMap.png") center/100% 100% no-repeat;
        height: 42vh;
    }
}*/

/*@media (min-width: 1024px) {
      
        .phases {
          display: flex;
          justify-content: space-between;
          padding: 5px;
        
  
          .featured-product {
            border-radius: 1rem;
            width: 25%;
            margin: 10px;
            background: rgba(48, 48, 47, 0.788);
            border: 5px solid rgba(78, 73, 2, 0.808);
            padding: 1rem;
            margin-left: 1rem 0;
          }
        }
      }*/