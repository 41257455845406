

  .cart-count {
    margin-left: -10px;
    background-color: black;
    color: white;
    padding-right: 4px;
    border-radius: 10px;
  }

