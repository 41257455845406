.hero-image {
  background: linear-gradient(rgba(0, 0, 0, 0), #00000000),
    url("../../assets/lomb-co-hero.jpeg") center/cover no-repeat;
    height: 85vh;
  border-bottom: solid;
  border-width: .5rem;
}

.hero-title {
  text-align: center;
  font-size: 2rem;
  color: white;
}

.hero-center {
  height: 80vh;
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

#btn-hero {
  margin: 0 10% ;
  width: 80%;
}

/*@media (min-width: 1024px) {
  .hero-image {
    font-size: 2.5rem;
    height: 85vh;
  }
  .hero-title {
    text-align: center;
    font-size: 3.5rem;
  }
  
}

@media (max-width: 1023px) {
  .hero-image {
    font-size: 2.5rem;
    height: 42vh;
  }
  .hero-title {
    text-align: center;
    font-size: 2rem;
  }
  
}*/


