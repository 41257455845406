.nav-menu {
  display: flex;
  padding: 1rem;
  align-items: center;
  height: 5vh;
  border-bottom: solid;
  border-width: .5rem;

  a {
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited {
      color: black;
    }
  }

  .logo {
    font-size: 1.5rem;
    padding-left: 6vw;
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin-left: auto;

    li {
      display: inline-block;
      margin: 0 1rem;
      
    }
  }
  

  .cart-container {
    margin-left: auto;
    cursor: pointer;
    padding-right: 6vw;

    img {
      width: 30px;
    }
  }
}

@media (min-width: 300px) and (max-width: 900px){
  .App {
    text-align: center;
    width: 100% !important;

    .nav-menu {
      display: column;
      padding: 1rem;
      align-items: center;
      height: 5vh;
      border-bottom: solid;
      border-width: .5rem;
    }
  }
}