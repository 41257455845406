.main-section {
  width: 80%;
  margin: 0 auto;
  //embedded level 1
  .main-section-middle {
    padding-top: 4vh;
    display: flex;
    justify-content: space-between;
    border-left: solid;
    border-right: solid;
    border-width: 1.5rem;
    //embedded level 2a
    .main-section-image {
      width: 25vw;
      margin-left: 10vw;
    }
    //embedded level 2b
    .main-section-description {
      width: 25vw;
      margin-right: 11vw;
      padding-top: 2vh;
      //embedded level b3
      p {
        font-size: 1.1rem;
        line-height: 1.5; 
        text-align: center;
      }
      //embedded level b3
      h2 {
        font-size: 1.1rem;
        text-align: center;
      }
      //embedded level b3
      // Button recieves app scss class attribute as method for site
      .buzzton {
        text-align: center;
      }
    }
    }
  }

  @media (min-width: 300px) and (max-width: 900px){
    .main-section {
      width: 96% !important;
      margin: 0 auto;
      //embedded level 1
      .main-section-middle {
        padding-top: 1vh;
        display: block !important;
        justify-content: space-between;
        border-left: none !important;
        border-right: none !important;
        border-width: .5rem !important;
      }
        //embedded level 2a
        .main-section-image {
          width: 60% !important;
          margin: auto !important;
        }
        //embedded level 2b
        .main-section-description {
          width: 90% !important;
          margin: auto !important;
          padding-top: 1vh !important;
          //embedded level b3
          p {
            font-size: 1.1rem;
            line-height: 1.25 !important; 
            text-align: center;
          }
          //embedded level b3
          h2 {
            margin-block-start: 0 !important;
            font-size: 1.1rem;
            text-align: center;
          }
          //embedded level b3
          // Button recieves app scss class attribute as method for site
          .buzzton {
            text-align: center;
          }
        }
    }
  }
  /*@media (min-width: 1024px) {
    .main-section-middle {
      display: flex;
      justify-content: space-between;
      border: solid;

      .ms-m-image {
        max-width: 545px;
        flex: 1 0 545px;
      }

      .ms-m-description {
        margin-left: 30px;
        align-self: center;

        p {
          font-size: 1.1rem;
        }
      }
    }
  }*/
