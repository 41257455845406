.road-map-detail-container  {
    width: 45vw;
    margin-left: auto;
    margin-right: auto;
}

.roadmap-img {
    margin-top: 1vh;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 40vh;
    width: 45vw;
    text-align: center;
    border: solid;
    border-width: 2rem;
}

h2 {
    text-align: center;
    margin-bottom: 7px;
}
h3 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0 !important;
}
ul {
    margin-top: 0px;
    padding-inline-start: 0 !important;
}
li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    //color: red;  works with !important override for relative class
}
.basicLine1 {
    //color: blue !important; //working
    margin: 0;
    //h5 header styles
    display: block;
    font-size: .83em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1px;
    font-weight: bold;
   
    
}
.basicLine2 {
    margin-top: 10px;
    //h2 header styles
    display: block;
    font-size: 1em;
    margin-bottom: 0.1px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
.basicLine3 {
    list-style-type: none;
    text-align: center;
    display: inline;
    padding: 16px;
    font-size: .83em;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

.basicLine4 {
    //color: blue !important; //working
    margin-top: 20px;
    //h5 header styles
    display: block;
    font-size: .95em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 5px;
    font-weight: bold;
   
    
}
.basicLine5 {
    //color: blue !important; //working
    margin: 0;
    //h5 header styles
    display: block;
    font-size: .83em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0px;
    font-weight: bold;
   
    
}
.basicLine6 {
    //color: blue !important; //working
    margin-top: 20px;
    //h5 header styles
    display: block;
    font-size: .83em;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1px;
    font-weight: bold;
   
    
}

@media (min-width: 300px) and (max-width: 900px){
    .road-map-detail-container  {
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        .road-map-art {
            img {
                border: solid;
                border-width: 2rem;
            }
        }
        .roadmap-img {
            margin-top: 1vh;
            margin-left: auto !important;
            margin-right: auto !important;
            display: inline !important;
            width: 75% !important;
            height: 100% !important;
            text-align: center !important;
        }
        
        h2 {
            text-align: center;
            margin-top: 0;
            margin-bottom: 0px;
        }
        h3 {
            text-align: center;
            margin-top: 0;
            margin-bottom: 0 !important;
        }
        .road-detail-writeup {
            ul {
                margin-top: 0px;
                padding-inline-start: 0 !important;
            }
            li {
                list-style-type: none;
                padding: 0;
                margin: 0;
                text-align: left !important;
                //color: red;  works with !important override for relative class
            }
            .basicTopic1 {
                //color: blue !important; //working
                text-align: center !important;
                margin: 0;
                //h5 header styles
                display: block;
                font-size: .83em;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 10px;
                font-weight: bold;      
            }
            .basicLine1 {
                //color: blue !important; //working
                text-align: center !important;
                margin: 0;
                //h5 header styles
                display: block;
                font-size: .83em;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 1px;
                font-weight: bold;      
            }
            .basicLine2 {
                text-align: center !important;
                margin-top: 10px;
                //h2 header styles
                display: block;
                font-size: 1em;
                margin-bottom: 0.1px;
                margin-left: 0;
                margin-right: 0;
                font-weight: bold;
            }
            .basicLine3 {
                text-align: center !important;
                margin-top: 0px;
                //h2 header styles
                display: block;
                font-size: 1em;
                margin-bottom: 0.1px;
                margin-left: 0;
                margin-right: 0;
                font-weight: bold;
            }
            
            
        }
    }
}
